//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import { VueAgile } from 'vue-agile'

export default {

    name: 'IndexFeaturedLogos',

    props: ['featuredLogos'],

    //components: { agile: VueAgile },

    data() {
        return {
            agileOptions: {
                autoplay: true,
                dots: false,
                navButtons: false,
                slidesToShow: 1,
                responsive: [
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 2,
                        }
                    },

                    {
                        breakpoint: 676,
                        settings: {
                            slidesToShow: 4,
                        }
                    },

                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 6,
                        }
                    }
                ]
            }
        }
    },

    computed: {

        isCrawler() {
            return this.$store.state.isCrawler
        }

    }

}

