//
//
//
//
//
//
//
//
//
//


export default {

    name: 'IndexMagazine',

}

