//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    name: 'SharedSectionStatistics',

    props: {
        content: {
            type: Object,
            default: {}
        },
        withBg: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            activeStat: false,
            statText: '',
        }
    },

    methods: {

            showStatsText(key, text) {

            if(this.activeStat === false) {
                this.slideDown(this.$refs.statContent)
            }

            this.statText = text
            this.activeStat = key

        },

        statActive(key) {
            return this.activeStat === key
        },

        slideDown( el, duration = 400 ) {

            el.style.removeProperty('display');
            let display = window.getComputedStyle(el).display;

            if (display === 'none')
                display = 'block';

            el.style.display = display;
            let height = el.offsetHeight;
            el.style.overflow = 'hidden';
            el.style.height = 0;
            el.style.paddingTop = 0;
            el.style.paddingBottom = 0;
            el.style.marginTop = 0;
            el.style.marginBottom = 0;
            el.offsetHeight;
            el.style.boxSizing = 'border-box';
            el.style.transitionProperty = "height, margin, padding";
            el.style.transitionDuration = duration + 'ms';
            el.style.height = height + 'px';
            el.style.removeProperty('padding-top');
            el.style.removeProperty('padding-bottom');
            el.style.removeProperty('margin-top');
            el.style.removeProperty('margin-bottom');
            window.setTimeout( () => {
                el.style.removeProperty('height');
                el.style.removeProperty('overflow');
                el.style.removeProperty('transition-duration');
                el.style.removeProperty('transition-property');
            }, duration);

        },

    },

}

