//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    name: 'SharedSectionStatisticsContainer',

    props: {
        content: {
            type: Object,
            default: {}
        },
    },

    data() {
        return {
            activeStat: false,
            statText: '',
        }
    },

    methods: {

        startCounter (isVisible, entry) {

            if(isVisible) {
                this.animateValue(entry.target, 0, entry.target.getAttribute('data-number'), 3000)
            }

        },

        animateValue(el, start, end, duration) {
            var range = end - start;
            var current = start;
            var increment = end > start? 1 : -1;
            var stepTime = Math.abs(Math.floor(duration / range));
            //var obj = document.getElementById(id);
            var timer = setInterval(function() {
                current += increment;
                el.textContent = current;
                if (current == end) {
                    clearInterval(timer);
                }
            }, stepTime);
        },

        showStatsText(key, event) {

            const text = this.$refs[key + 'Text'].textContent

            this.$emit('show-stats-text', key, text)
            this.activeStat = key

        },

        statActive(key) {
            return this.activeStat === key
        },

    },

}

