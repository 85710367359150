//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { autocomplete } from '~/mixins/autocomplete'

export default {

    name: 'IndexHeader',

    mixins: [autocomplete],

    data() {
        return {
            loadApi: false,
        }
    },

    computed: {

        isCrawler() {
            return this.$store.state.isCrawler
        },

        headerBackground() {

            if(this.isCrawler) {
                return { backgroundColor: '#3e4046' }
            } else {
                return { backgroundImage: 'url(' + require('~/assets/images/index/header-bg.jpg') + ')' }
            }

        }

    }

}

