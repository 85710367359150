//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import {VueAgile} from "vue-agile"

export default {

    name: 'SharedBuyerprogramSlider',

    props: ['buyerPrograms'],

    //components: { agile: VueAgile },

    data() {
        return {
            agileOptions: {
                autoplay: false,
                dots: true,
                navButtons: true,
                slidesToShow: 1,
                responsive: [

                    {
                        breakpoint: 676,
                        settings: {
                            slidesToShow: 2,
                        }
                    },

                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 3,
                        }
                    }
                ]
            }
        }
    },

    computed: {

        isCrawler() {
            return this.$store.state.isCrawler
        }

    }

}

