import { render, staticRenderFns } from "./IndexHostedBuyer.vue?vue&type=template&id=2f193c7a&"
import script from "./IndexHostedBuyer.vue?vue&type=script&lang=js&"
export * from "./IndexHostedBuyer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SharedBuyerprogramSlider: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/shared/SharedBuyerprogramSlider.vue').default})
