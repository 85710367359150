//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import GoogleAutocompleteMixin from '~/components/google/mixins/GoogleAutocompleteMixin'

export default {

    name: 'GoogleAutocompleteSelect',

    mixins: [GoogleAutocompleteMixin],

}
