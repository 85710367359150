import { render, staticRenderFns } from "./SharedSectionStatistics.vue?vue&type=template&id=0b306773&"
import script from "./SharedSectionStatistics.vue?vue&type=script&lang=js&"
export * from "./SharedSectionStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SharedSectionStatisticsContainer: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/shared/section-statistics/SharedSectionStatisticsContainer.vue').default})
