//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    name: 'Index',

    data() {
        return {
            page: {},
            showNewsletterPopup: false,
        }
    },

    head() {

        return this.$head()
            .setPage(this.page)
            .setBodyAttrs('index-wrap')
            .get()
    },

    async asyncData({ $xhrHelper, $logger }) {

        let page= {}, featuredLogos = {}, content = {}, buyerPrograms = {}, banner = {}, news = {}

        try {
            const resource = await $xhrHelper.getResourceById('index')

            page = resource.page
            featuredLogos = resource.featuredLogos
            content = resource.content
            buyerPrograms = resource.buyerPrograms
            banner= resource.banner
            news = resource.news

        } catch (e) {
            $logger.captureException(e)
        }

        return {
            page: page,
            featuredLogos: featuredLogos,
            content: content,
            buyerPrograms: buyerPrograms,
            banner: banner,
            news: news
        }

    },

    methods: {

        handleScroll: function () {

            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if(scrollTop>=document.body.scrollHeight/5) {
                this.showNewsletterPopup = true
            }

        },

    },

    computed: {

        isCrawler() {
            return this.$store.state.isCrawler
        }

    }

}

