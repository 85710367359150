import { render, staticRenderFns } from "./index.vue?vue&type=template&id=33572f94&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=router"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHeader: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/index/IndexHeader.vue').default,IndexFeaturedLogos: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/index/IndexFeaturedLogos.vue').default,IndexDescription: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/index/IndexDescription.vue').default,SharedSectionStatistics: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/shared/SharedSectionStatistics.vue').default,IndexHostedBuyer: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/index/IndexHostedBuyer.vue').default,IndexTestimonials: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/index/IndexTestimonials.vue').default,IndexWhoIs: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/index/IndexWhoIs.vue').default,IndexNews: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/index/IndexNews.vue').default,IndexMagazine: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/index/IndexMagazine.vue').default})
