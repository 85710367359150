//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import {VueAgile} from "vue-agile"

export default {

    name: 'IndexTestimonials',

    //components: { agile: VueAgile },

    data() {
        return {
            testimonials: [
                {
                    author: 'Rike Kreft, Director Sales & Marketing EMEA at ESA',
                    authorImage: require('~/assets/images/index/testimonials/rikequote.png'),
                    quote: 'ESA Latin America is happy to be represented on this great MICE platform to reach new potential buyers by showcasing our 8 beautiful Latin American destinations with this excellent tool.'
                },
                {
                    author: 'Barış Şencan, intern. Relations Executive at Ace of MICE Exhibition',
                    authorImage: require('~/assets/images/index/testimonials/barisquote.png'),
                    quote: 'EVINTRA is a strong partner for our MICE exhibition, delivering extensive global reach as well as high quality buyers since 2012!'
                },
                {
                    author: 'David Sand, CEO at UWIN IWIN Incentives',
                    authorImage: require('~/assets/images/index/testimonials/davidquote.png'),
                    quote: 'EVINTRA is a powerful player in the connectivity and trading arena of the multi-billion dollar MICE Industry. It creates a cost effective and logical way to connect buyers and sellers in our digital age'
                },
                {
                    author: 'Lorena K., Executive intern. Businesses at the Chamber of Commerce Panama',
                    authorImage: require('~/assets/images/index/testimonials/lorenaquote.png'),
                    quote: 'We are happy to have found a reliable partner for our hosted buyer programs in EVINTRA, which helped us to increase attendance of international buyer from EMEA and US markets.'
                }
            ],
            agileOptions: {
                autoplay: false,
                dots: true,
                navButtons: true,
                slidesToShow: 1,
            }
        }

    },

    computed: {

        isCrawler() {
            return this.$store.state.isCrawler
        }

    }

}

